import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { setPartner } from 'store/auth/partnerSlice'
import { apiSignIn } from 'services/AuthService'
import { getMyDetails } from 'services/CommonService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { tene_PREFIX_PATH } from 'constants/route.constant'
import { ADMIN, TENE_ADMIN, TEST_STATION_ADMIN, TEST_STATION_USER, USER } from 'constants/roles.constant'
import { getAuthenticatedEntryPointForLoggedInuser } from './redirectHook'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)
	// const currentPartner = useSelector(state => state.auth.partner);

    const signIn = async (values) => {
        try {
			// const resp = await apiSignIn(values)
			// if (resp.data) {

			// 	if(resp.data.partner) {
			// 		dispatch(setPartner(resp.data.partner));
			// 	}
			// 	// Temporary login fixed later we need to change as per requirement
			// 	resp.data = {
            //         token : resp.data.jwt,
            //         user : {
            //             id: resp.data.user.id,
            //             userName: resp.data.user.username,
            //             email: resp.data.user.email,
            //             authority: getLoggedInUserAuthorities(resp.data.user),
            //             accountUserName: resp.data.user.name,
			// 			avatar: resp.data.user.avatar?.formats?.thumbnail?.url
            //         }
            //     }
			// 	const { token } = resp.data
			// 	dispatch(onSignInSuccess(token))
			// 	dispatch(setUser(resp.data.user))
			// 	const redirectUrl = values.password
			// 	.indexOf(process.env.REACT_APP_default_password_prefix || 'default') === 0 ? `${tene_PREFIX_PATH}/change-password`: getAuthenticatedEntryPointForLoggedInuser(resp.data.user.authority);
			// 	// const redirectUrl = query.get(REDIRECT_URL_KEY)
			// 	// navigate(redirectUrl ? redirectUrl : getAuthenticatedEntryPointForLoggedInuser(resp.data.user.authority))
			// 	navigate(redirectUrl);
            //     return {
            //         status: 'success',
            //         message: ''
            //     }
			// }
			const resp = await apiSignIn(values)
			navigate('/initial-loading')
			const redirectUrl = values.password
			.indexOf(process.env.REACT_APP_default_password_prefix || 'default') === 0 ? `${tene_PREFIX_PATH}/change-password`: null;
			await performSignIn(resp.data.jwt, dispatch, navigate, redirectUrl);
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.error?.message || errors.toString()
            }
		}
    }
	// const getAuthenticatedEntryPointForLoggedInuser = (authority) => {
	// 	if(authority.indexOf(ADMIN) !== -1) {
	// 		return `${tene_PREFIX_PATH}/usermanagement`;
	// 	}else if(authority.indexOf(USER) !== -1) {
	// 		return `${tene_PREFIX_PATH}/test-stations`;
	// 	}
	// 	return appConfig.authenticatedEntryPath
	// }
	// const getLoggedInUserAuthorities = (user) => {
	// 	const authority = [];//['partner_admin'];
	// 	if(user.role?.name == 'partner_admin') {
	// 		authority.push(USER);
	// 	}
	// 	if(user.role?.name == 'Partner_User') {
	// 		authority.push(USER);
	// 	}
	// 	return authority;
	// }

    // const handleSignOut = ()  => {
	// 	dispatch(onSignOutSuccess())
	// 	dispatch(setUser(initialState))
	// 	signOutRedirect();
	// }
	// const signOutRedirect = () => {
	// 	if(currentPartner && currentPartner.id !== -1) {
	// 		navigate(`/sign-in/${currentPartner.shortName}`)
	// 	}else {
	// 		navigate(appConfig.unAuthenticatedEntryPath)
	// 	}
	// }

    // const signOut = async () => {
	// 	// await apiSignOut()
	// 	handleSignOut()
	// }

	const handleSignOut = () => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		// await apiSignOut()
		handleSignOut()
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
        signOut
    }
}

const performSignIn = async (jwt, dispatch, navigate, redirectURL) => {

	dispatch(onSignInSuccess(jwt))
	setTimeout(async () => {
		const me = await getMyDetails();
		const user = {
			id: me.data.id,
			userName: me.data.username,
			email: me.data.email,
			authority: getUserAuthorities(me.data),//['admin', 'user'],
			accountUserName: me.data.name,
			avatarId: me?.data?.avatar?.id,
			...me.data
		};
		const url = me?.data?.avatar?.formats?.thumbnail?.url;
		dispatch(setUser({ ...user, avatar: url }))
		// setTimeout(async ()=> {
			if(getAuthenticatedEntryPointForLoggedInuser(user.authority) === "/access-denied"){
				navigate(getAuthenticatedEntryPointForLoggedInuser(user.authority))
			}else{
				navigate(getAuthenticatedEntryPointForLoggedInuser(user.authority)) // (to redirect by-default "/change-password" if pass is not updated  user or ) ||
			}
		// navigate(redirectURL || getAuthenticatedEntryPointForLoggedInuser(user.authority))
		// }, 0)
	}, 1)
}

const getUserAuthorities = (user) => {
	const authorities = []
	const teneRoles = user?.role;
	
	// // Is tene Admin role?
	if (teneRoles?.id === parseInt(process.env.REACT_APP_Pest_Test_Tene_Admin_role_id)) {
		authorities.push(TENE_ADMIN)
	}
	// Is test station admin role?
	if (teneRoles?.id === parseInt(process.env.REACT_APP_Pest_Test_Test_Station_Admin_role_id)) {
		authorities.push(TEST_STATION_ADMIN)
	}
	// Is test station user role?
	if (teneRoles?.id === parseInt(process.env.REACT_APP_Pest_Test_Test_Station_User_role_id)) {
		authorities.push(TEST_STATION_USER)
	}
	// if(!authorities?.length){
	// 	authorities.push("No Authority")
	// }
	return authorities;
}

export default useAuth