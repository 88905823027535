import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import partner from './partnerSlice'

const reducer = combineReducers({
    session,
    user,
    partner
})

export default reducer