const { TEST_STATION_ADMIN, TENE_ADMIN, TEST_STATION_USER } = require("constants/roles.constant");
const { tene_PREFIX_PATH } = require("constants/route.constant");

exports.getAuthenticatedEntryPointForLoggedInuser = (authorities) => {
	if(authorities.find(ele => ele === TENE_ADMIN)) { 
		return `${tene_PREFIX_PATH}/test-stations`; 
	}else if(authorities.find(ele => ele === TEST_STATION_ADMIN)) { 
		return `${tene_PREFIX_PATH}/candidates`; 
	}
	else if(authorities.find(ele => ele === TEST_STATION_USER)) { 
		return `${tene_PREFIX_PATH}/dashboard`; 
	}else { 
		return `/access-denied`; 
	}
}