import ApiService from "./ApiService"

export async function getMyDetails () {
    return ApiService.fetchData({
        url: '/users/me?populate=avatar,role,test_station,test_station.parent_org,test_station.client_relams,test_station.client_relams.training_realm',
        method: 'get'
    }) 
}
// **JSR TODO: Delete
// export async function apiGetNotificationCount () {
//     return ApiService.fetchData({
//         url: '/notification/count',
//         method: 'get'
//     })
// }

// export async function apiGetNotificationList () {
//     return ApiService.fetchData({
//         url: '/notification/list',
//         method: 'get'
//     })
// }

// export async function apiGetSearchResult (data) {
//     return ApiService.fetchData({
//         url: '/search/query',
//         method: 'post',
//         data
//     })
// }