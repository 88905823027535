import { App_Type } from 'constants/api.constant'
import ApiService from './ApiService'

export async function apiSignIn (data) {
    // **JSR,...TODO: Here we need to authenticate user from /pw/auth/local
    // Enable code below to authenticate as partner User.
    return ApiService.fetchData({
        url: '/auth/local?populate=*',
        method: 'post',        
        data: {
            identifier: data.identifier,
            password: data.password,
        }
    })
    // return ApiService.fetchData({
    //     url: '/auth/local',
    //     method: 'post',
    //     data
    // })
}
export async function apiSignInPartner (partnershortname) {
    return ApiService.fetchData({
        url: `/partners/get/${partnershortname}`,
        method: 'get'
    })
}
export async function apiValidatePartnerShortName (partnershortname) {
    return ApiService.fetchData({
        url: `/partners/validate/${partnershortname}`,
        method: 'get'
    })
}
export async function apiRegisterAdmin(data) {
    return ApiService.fetchData({
        url: `/pw/auth/register-admin`,
        method: 'post',
        data
    })
}
export async function apiAcceptTermAndConditions(data) {
    return ApiService.fetchData({
        url: `/pw/acceptTermAndConditions`,
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiAskOtpResetPassword (data) {
    return ApiService.fetchData({
        url: `auth/reset-password-request`,
        method: 'post',
        data
    })
}

export async function apiVerifyOtpResetPassword (data) {
    return ApiService.fetchData({
        url: `auth/verify/reset-password-request`,
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}
export async function apiChangePassword (data) {
    return ApiService.fetchData({
        url: '/auth/change-password',
        method: 'post',
        data
    })
}
