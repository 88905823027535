import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
        "id": -1,
        "fullName": "",
        "shortName": "unknownPartner",
        "isManufacturer": false,
        "phoneNumber": "",
        "address": "",
        "pincode": "0000",
        "appName": "",
        "tagline": "Krishikaay hitay",
        "term_and_condition": "**Partner_term and condition** for partner1",
        "loginURL": null,
        "isactive": null,
        "contentManagerCanEdit": null,
        "logo": {
          "formats": {
            "thumbnail": {
              "url": "https://tene-drs.s3.ap-south-1.amazonaws.com/thumbnail_919899077645_d8994d7eaf.jpeg"
            },
            "small": {
              "url": "https://tene-drs.s3.ap-south-1.amazonaws.com/small_919899077645_d8994d7eaf.jpeg"
            }
          },
          // "url": "/img/logo/logo-light-full.png",
          "url": "/img/logo/PesTest_logo.png",
        },
        "splashScreen": {
          "url": "/img/others/auth-cover-bg.jpg",
        },
    // tagLine: 'Krishikaay hitay',
    // splashScreen: '/img/others/auth-cover-bg.jpg',
    // authority: []
}

export const partnerSlice = createSlice({
	name: 'auth/partner',
	initialState,
	reducers: {
        setPartner: (_, action) => action.payload,
        userLoggedOut: () => initialState,
	},
})

export const { setPartner } = partnerSlice.actions

export default partnerSlice.reducer